<template>
  <div class="footer">
    <div class="container">
      <b-row>

        <b-col lg="4">
          <h5>
            About Us
          </h5>
          <p>
            Fulfillin is a multi-functional fulfillment tool that
            allows Amazon FBA, Amazon Vendor Central,
            Cdiscount FBC and other platform sellers to fulfill
            (scan, print and send) their packages to respective
            fulfillment centers much more effectively and
            professionally. We are here to support you in your
            eCommerce business growth by reducing the error
            count and time needed to create your fulfillment
            shipments of any complexity.
          </p>
          <h5>
            Payments accepted
          </h5>
          <button class="btn">
            VISA
          </button>
          <button class="btn">
            PayPal
          </button>
          <button class="btn">
            MasterCard
          </button>
        </b-col>

        <b-col lg="4">
          <h5>
            Navigation
          </h5>
          <div class="navigation">
            <li @click="$emit('sendSectionToApp', 'overview')">
              <router-link
                class="link"
                :to="{ name: 'home' }"
              >
                OVERVIEW
              </router-link>
            </li>
            <li @click="$emit('sendSectionToApp', 'features')">
              <router-link
                class="link"
                :to="{ name : 'home' }"
              >
                FEATURES
              </router-link>
            </li>
            <li @click="$emit('sendSectionToApp', 'integrations')">
              <router-link
                class="link"
                :to="{ name : 'home' }"
              >
                INTEGRATIONS
              </router-link>
            </li>
            <li @click="$emit('sendSectionToApp', 'getStarted')">
              <router-link
                class="link"
                :to="{ name : 'home' }"
              >
                GET STARTED
              </router-link>
            </li>
            <li>
                <a href="https://help.fulfillin.com/" class="link">
                  HELP/FAQ
                </a>
            </li>
            <li>
              <a
                class="link"
                href="https://app.fulfillin.com"
              >
                SIGN IN/REGISTER
              </a>
            </li>
          </div>
        </b-col>

        <b-col lg="4">
          <h5>
            Got a question?
          </h5>
          <div v-if="!ui.messageSent">
            <form class="was-validated" @submit.prevent="submitContactForm">
              <div :class="{'mb-1': true, invalid: ui.errors.name}">
                <input
                  class="input mb-0"
                  type="text"
                  placeholder="Name"
                  v-model="contactForm.name"
                >
                <div class="invalid-feedback">Please enter your name.</div>
              </div>
              <div :class="{'mb-1': true, invalid: ui.errors.email}">
                <input
                  class="input mb-0"
                  type="text"
                  placeholder="Email"
                  v-model="contactForm.email"
                >
                <div class="invalid-feedback">Please enter valid email.</div>
              </div>
              <div class="mb-1">
                <input
                  class="input mb-0"
                  type="text"
                  placeholder="Phone"
                  v-model="contactForm.phone"
                >
              </div>
              <div :class="{'mb-1': true, invalid: ui.errors.subject}">
                <input
                  class="input mb-0"
                  type="text"
                  placeholder="Subject"
                  v-model="contactForm.subject"
                >
                <div class="invalid-feedback">Please enter subject.</div>
              </div>
              <div :class="{'mb-1': true, invalid: ui.errors.message}">
              <textarea
                id=""
                cols="30"
                rows="5"
                style="height: auto"
                placeholder="Message"
                class="input mb-0"
                v-model="contactForm.message"
              />
                <div class="invalid-feedback">Please enter message.</div>
              </div>
              <button class="btn" :disabled="ui.sending">{{ ui.sending ? 'Sending...' : 'Send' }}</button>
            </form>
          </div>
          <div v-else>
            <div class="alert alert-success mt-1 p-2 text-xl-center">
              Message sent successfully!
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="container-fluid">
      2021 © Fulfillin
    </div>
  </div>
</template>

<script>
import Axios from 'axios'
import {
  BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'Footer',
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      contactForm: {
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
      },
      ui: {
        sending: false,
        messageSent: false,
        errors: {
          name: false,
          email: false,
          subject: false,
          message: false,
        },
      },
    }
  },
  methods: {
    async submitContactForm() {
      this.ui.sending = false
      const hasErrors = this.validateContactForm()
      if (hasErrors) {
        return false
      }
      this.ui.sending = true

      const data = {
        from: {
          type: 'user',
          username: this.contactForm.name,
          email: this.contactForm.email,
        },
        to: {
          type: 'admin',
          id: 1,
        },
        content: `
Name: ${this.contactForm.name}\n
Email: ${this.contactForm.email}\n
Phone: ${this.contactForm.phone}\n
Subject: ${this.contactForm.subject}\n
Message: ${this.contactForm.message}\n
`,
        attachments: [],
      }
      const headers = { Authentication: 'AccessToken: 46a438a307c9f1bc8d4ac175e0a20945b332814f959e46f2208e93b203ea0283' }
      await Axios.post('https://api.customerly.io/v1/messages', data, { headers })
      this.ui.messageSent = true
      return false
    },
    validateContactForm() {
      let errorFound = false
      this.ui.errors.name = false
      this.ui.errors.email = false
      this.ui.errors.subject = false
      this.ui.errors.message = false

      if (!this.contactForm.name) {
        this.ui.errors.name = true
        errorFound = true
      }
      if (!this.contactForm.email || !this.validateEmail(this.contactForm.email)) {
        this.ui.errors.email = true
        errorFound = true
      }
      if (!this.contactForm.subject) {
        this.ui.errors.subject = true
        errorFound = true
      }
      if (!this.contactForm.message) {
        this.ui.errors.message = true
        errorFound = true
      }

      return errorFound
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: #3f4349;
  padding-top: 20px;
  color: white;
  margin: 0;
  position: relative;

  .was-validated .invalid .invalid-feedback {
    display: block;
  }

  .was-validated .invalid input, .was-validated .invalid textarea {
    border-color: #EA5455;
  }

  h5 {
    font-family: 'Barlow', sans-serif;
    font-weight: lighter;
    border-bottom: 3px solid white;
    padding-bottom: 5px;
    margin-bottom: 10px;
    color: white;
  }

  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    margin-bottom: 25px;
  }

  .btn {
    font-family: 'Barlow', sans-serif;
    font-weight: bolder;
    margin-right: 10px;
    color: black;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 20px;

    &:hover {
      background-color: #3f4349;
      color: white;
      border-color: white;
      transition: .5s ease all;
    }
  }

  .input {
    width: 100%;
    margin-bottom: 15px;
    padding: 15px;
    height: 50px;
    border-radius: 5px;
    border: 1px white solid;
    background-color: #3f4349;
    color: white;
  }

  form {
    .btn {
      width: 100%;
    }
  }

  li {
    border-bottom: 1px solid white;
    margin-bottom: 13px;

    .link {
      font-family: 'Open Sans', sans-serif;
      text-decoration: none;
      color: white;
      height: 28px;

      &:hover {
        font-size: 20px;
        transition: .5s ease all;
      }
    }
  }

  .navigation {
    padding-left: 0;
    list-style: none;
  }

  .container-fluid {
    background-color: #1b1c1e;
    color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    font-family: 'Barlow', sans-serif;
  }
}
</style>
