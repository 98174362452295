<template>
  <header v-click-outside="closeSidebar">
    <div class="container">
      <nav>
        <li
          class="navbar-brand"
          data-aos="zoom-in"
          data-aos-duration="1000"
          @click="$emit('sendSectionToApp', '')"
        >
          <router-link
            style="color: white"
            :to="{ name: 'home' }"
          >
            <span>FULFILL</span>
            <span style="color: #ff8c00;">IN</span>
          </router-link>
        </li>
        <ul
          v-show="!mobile"
          class="navigation"
        >
          <li
            data-aos="fade-down"
            data-aos-delay="500"
            data-aos-duration="1000"
            @click="$emit('sendSectionToApp', 'overview')"
          >
            <router-link
              class="link"
              :to="{ name: 'home' }"
            >
              OVERVIEW
            </router-link>
          </li>
          <li
            data-aos="fade-down"
            data-aos-delay="500"
            data-aos-duration="1100"
            @click="$emit('sendSectionToApp', 'features')"
          >
            <router-link
              class="link"
              :to="{ name : 'home' }"
            >
              FEATURES
            </router-link>
          </li>
          <li
            data-aos="fade-down"
            data-aos-delay="500"
            data-aos-duration="1200"
            @click="$emit('sendSectionToApp', 'integrations')"
          >
            <router-link
              class="link"
              :to="{ name : 'home' }"
            >
              INTEGRATIONS
            </router-link>
          </li>
          <li
            data-aos="fade-down"
            data-aos-delay="500"
            data-aos-duration="1300"
            @click="$emit('sendSectionToApp', 'getStarted')"
          >
            <router-link
              class="link"
              :to="{ name : 'home'}"
            >
              GET STARTED
            </router-link>
          </li>
          <li
            data-aos="fade-down"
            data-aos-delay="500"
            data-aos-duration="1400"
            @click="$emit('sendSectionToApp', 'about')"
          >
            <router-link
              class="link"
              :to="{ name : 'home' }"
            >
              ABOUT US
            </router-link>
          </li>
          <li
            data-aos="fade-down"
            data-aos-delay="500"
            data-aos-duration="1500"
          >
              <a href="https://help.fulfillin.com/" class="link">
                  HELP/FAQ
              </a>
          </li>
          <li
            data-aos="fade-down"
            data-aos-delay="500"
            data-aos-duration="1600"
          >
            <a
              class="link"
              href="https://app.fulfillin.com"
            >
              SIGN IN/REGISTER
            </a>
          </li>
        </ul>
        <div class="icon">
          <i
            v-show="mobile"
            class="far fa-bars"
            :class="{'icon-active' : mobileNav}"
            @click="toggleMobileNav"
          />
        </div>
        <transition name="mobile-nav">
          <div
            v-show="mobileNav"
            class="dropdown-nav"
          >
            <ul>
              <li
                class="navbar-brand"
                style="height: 53px"
                @click="$emit('sendSectionToApp', '')"
              >
                <router-link
                  style="color: white"
                  :to="{ name: 'home' }"
                >
                  <span style="cursor: pointer">FULFILL</span>
                  <span style="color: #ff8c00; cursor: pointer">IN</span>
                </router-link>
              </li>
              <li
                data-aos="fade-down"
                data-aos-delay="500"
                data-aos-duration="1000"
                @click="$emit('sendSectionToApp', 'overview')"
              >
                <router-link
                  class="link"
                  :to="{ name : 'home' }"
                >
                  OVERVIEW
                </router-link>
              </li>
              <li
                data-aos="fade-down"
                data-aos-delay="500"
                data-aos-duration="1100"
                @click="$emit('sendSectionToApp', 'features')"
              >
                <router-link
                  class="link"
                  :to="{ name : 'home' }"
                >
                  FEATURES
                </router-link>
              </li>
              <li
                data-aos="fade-down"
                data-aos-delay="500"
                data-aos-duration="1200"
                @click="$emit('sendSectionToApp', 'integrations')"
              >
                <router-link
                  class="link"
                  :to="{ name : 'home' }"
                >
                  INTEGRATIONS
                </router-link>
              </li>
              <li
                data-aos="fade-down"
                data-aos-delay="500"
                data-aos-duration="1300"
                @click="$emit('sendSectionToApp', 'getStarted')"
              >
                <router-link
                  class="link"
                  :to="{ name : 'home' }"
                >
                  GET STARTED
                </router-link>
              </li>
              <li
                data-aos="fade-down"
                data-aos-delay="500"
                data-aos-duration="1400"
                @click="$emit('sendSectionToApp', 'about')"
              >
                <router-link
                  class="link"
                  :to="{ name : 'home' }"
                >
                  ABOUT US
                </router-link>
              </li>
              <li>
                  <a href="https://help.fulfillin.com/" class="link">
                      HELP/FAQ
                  </a>
              </li>
              <li>
                <a
                  class="link"
                  href="https://app.fulfillin.com"
                >
                  SIGN IN/REGISTER
                </a>
              </li>
            </ul>
          </div>
        </transition>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  components: {
  },
  props: {
    scroll: {
      type: Function,
      default: null,
    },
    watcher: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      scrollPosition: null,
      mobile: false,
      mobileNav: false,
      windowWidth: null,
      section: '',
    }
  },

  /* eslint-disable */
  created() {
    window.addEventListener('resize', this.checkScreen)
    this.checkScreen()
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 27) {
        this.closeSidebar()
      }
    })
  },

  watch: {
    watcher(newValue, oldValue) {
      this.closeSidebar()
    },
    '$route'() {
      this.closeSidebar()
    },
  },
  /* eslint-enable */

  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav
    },

    checkScreen() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth <= 1170) {
        this.mobile = true
        return
      }
      this.mobile = false
      this.mobileNav = false
    },

    closeSidebar() {
      this.mobileNav = false
    },
  },
}
</script>

<style lang="scss" scoped>
header {
  background-color: #3F4349;
  z-index: 99;
  width: 100%;
  position: fixed;
  transition: .5s ease all;
  color: white;
  padding-top: 12px;
  height: 60px;
  box-shadow: black 0 0 10px;

  nav {
    position: relative;
    display: flex;
    flex-direction: row;
    transition: .5s ease all;
    margin: 0 auto;

    ul,
    .link {
      font-family: 'Open Sans', sans-serif;
      font-weight: 500;
      color: white;
      list-style: none;
      text-decoration: none;
    }

    li {
      text-transform: uppercase;
      padding: 0 12px 12px 12px;
      margin-left: 6px;
      margin-right: 0;
    }

    .link {
      font-size: 15px;
      padding-top: 4px;
      transition: .5s ease all;
      border-top: 3px solid transparent;

      &:hover {
        color: darkorange;
        border-color: darkorange;
      }
    }

    .navbar-brand {
      display: flex;
      font-family: 'Barlow', sans-serif;
      font-size: 24px;
      font-weight: bold;
    }

    .navigation {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
    }

    .icon {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 24px;
      height: 100%;

      i {
        cursor: pointer;
        font-size: 24px;
        transition: 0.8s ease all;
      }
    }

    .dropdown-nav {
      display: flex;
      flex-direction: column;
      position: fixed;
      width: 250px;
      max-width: 250px;
      height: 100%;
      background-color: black;
      top: 0;
      left: 0;
      padding-top: 5px;

      li {
        margin-left: 0;
        padding-left: 0;
        height: 36px;

        .link {
          color: white;

          &:hover {
            background-color: white;
            color: black;
            border-top: 0;
            font-size: 20px;
            padding: 5px;
          }
        }
      }

      .navbar-brand {
        font-family: 'Barlow', sans-serif;
        font-size: 24px;
        font-weight: bold;
        border-bottom: 2px solid;
        border-color: white;
        padding-bottom: 11px;
        padding-top: 5px;
        margin-right: 30px;
        margin-bottom: 10px;
      }
    }
  }

    .icon-active {
      transform: rotate(180deg);
    }

    .mobile-nav-enter,
    .mobile-nav-leave-to {
      transform: translateX(-250px);
    }

    .mobile-nav-enter-active,
    .mobile-nav-leave-active {
      transition: all 1s;
    }

    .mobile-nav-enter-to {
      transform: translateX(0);
    }
  }
</style>
