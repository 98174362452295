import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/auth',
      name: 'mirakl-auth',
      component: () => import('@/views/MiraklAuth.vue'),
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/help',
      name: 'help',
      component: () => import('@/views/Help.vue'),
    },
    {
      path: '/help/general/:pageProp',
      name: 'documentationGeneral',
      component: () => import('@/views/Documentation.vue'),
    },
    {
      path: '/help/shipments/:pageProp',
      name: 'documentationShipments',
      component: () => import('@/views/Documentation.vue'),
    },
    {
      path: '/help/dbschenker/:pageProp',
      name: 'documentationDbschenker',
      component: () => import('@/views/Documentation.vue'),
    },
    {
      path: '/help/mirakl/:pageProp',
      name: 'documentationMirakl',
      component: () => import('@/views/Documentation.vue'),
    },
    {
      path: '/help/mirakl',
      name: 'documentationMiraklReroute',
      redirect: { path: '/help/mirakl/general' },
    },
    {
      path: '*',
      name: '404',
      component: () => import('@/views/404.vue'),
    },
  ],
})

export default router
